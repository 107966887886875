import React from "react";
import { Link } from "react-router-dom";
import "../scss/nav.scss";
function Nav() {
  return (
    <div className="Bar">
      <a href="#home">Home</a>
      <a href="#skills">Skill</a>
      <a href="#journey">Journey</a>
      <a href="#contact">Contact</a>
    </div>
  );
}
export default Nav;
