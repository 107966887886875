import "../../node_modules/bootstrap/dist/js/bootstrap.js";

import "../scss/job.scss";
import webDevBW from "../img/Web developer BW.png";
import webDev from "../img/Web developer Color.png";
import contentCreatorBW from "../img/Content Creator BW.png";
import contentCreator from "../img/Content Creator Color.png";
import productEngBW from "../img/Product Engineer BW.png";
import productEng from "../img/Product Engineer Color.png";
import businessStudBW from "../img/Student BW.png";
import businessStud from "../img/Student Color.png";

function Jobcardcontent(props) {
  let skills = props.contentList;
  let skillsList = [];

  for (let skill of skills) {
    skillsList.push(<li key={skill}>{skill}</li>);
  }
  return <ul>{skillsList}</ul>;
}

function Jobcard() {
  let data = {
    webDev: {
      id: 1,
      tagid: "webDev",
      title: "Web Developer",
      BWimg: webDevBW,
      BWimgdescription: "Black&White image of web developer",
      Cimg: webDev,
      Cimgdescription: "Colored image of web developer",
      content: ["Javascript", "CSS", "HTML"],
    },
    contentCreator: {
      id: 2,
      tagid: "contentCreator",
      title: "Content Creator",
      BWimg: contentCreatorBW,
      BWimgdescription: "Black&White image of content creator",
      Cimg: contentCreator,
      Cimgdescription: "Color image of content creator",
      content: ["Copywriting", "Photoshop", "Video Edit"],
    },
    productEng: {
      id: 3,
      tagid: "productEng",
      title: "Product Engineer",
      BWimg: productEngBW,
      BWimgdescription: "Black&White image of product engineer",
      Cimg: productEng,
      Cimgdescription: "Colored image of product engineer",
      content: ["Python", "C++", "Solidwork", "AutoCAD"],
    },
    businessStud: {
      id: 4,
      tagid: "businessStud",
      title: "Business Student",
      BWimg: businessStudBW,
      BWimgdescription: "Black&White image of business student",
      Cimg: businessStud,
      Cimgdescription: "Colored image of business student",
      content: ["Supply Chain", "Marketing"],
    },
  };
  let jobCardArray = [];

  Object.keys(data).forEach((key) => {
    jobCardArray.push(
      <>
        <div id={data[key].tagid} className="col-10 col-sm-5 square flip ">
          <div className="cardContent mx-0 front">
            <p>{data[key].title}</p>
            <img src={data[key].BWimg} alt={data[key].BWimgdescription}></img>
          </div>
          <div className="cardContent mx-0 back ">
            <p>{data[key].title}</p>
            <img src={data[key].Cimg} alt={data[key].Cimgdescription}></img>
            <Jobcardcontent contentList={data[key].content}></Jobcardcontent>
          </div>
        </div>
        <div className=" w-100 bufferRow "></div>
      </>
    );
    if (data[key].id % 2 === 0) {
      jobCardArray.push(<div className="w-100 d-none d-md-block"></div>);
    }
  });
  return (
    <>
      <div id="skills" className="">
        <p className="subtitle">Who am I ?</p>

        <div className="cardGroup pt-5 row gx-3 gy-3 mx-auto">
          {jobCardArray}
        </div>
      </div>
    </>
  );
}

export default Jobcard;
