import HKSD from "../img/HKSD Logo.png";
import TG from "../img/TG Logo.png";
import Douglas from "../img/Douglas Logo.png";
import V2RF from "../img/Vision2Reality Logo.png";
import HKU from "../img/HKU Logo.png";
import HKIE from "../img/HKIE Logo.png";
import CHHA from "../img/CHHA Logo.png";
import HKSD_BG from "../img/HKSD_BG.png";
import HKU_BG from "../img/HKU_BG.png";
import TG_BG from "../img/TG_BG.png";
import Douglas_BG from "../img/Douglas_BG.png";
import HKIE_BG from "../img/HKIE_BG.png";
import CHHA_BG from "../img/CHHA_BG.png";
import V2RF_BG from "../img/V2RF_BG.png";
import FRDC_BG from "../img/FRDC_BG.png";
import FRDC from "../img/FRDC Logo.png";

const imgData = [
  {
    img: HKSD,
    imgname: "HKSD",
    title: "Volunteer",
    period: "May 2014 - Aug 2023",
    bg: HKSD_BG,
    jobdescription:
      "\tDuring my volunteer tenure at the Hong Kong Society for the Deaf from 2014 to 2023, I actively contributed to raising awareness about living with hearing impairments. I participated in promotional videos and talk shows, discussing daily life experiences and promoting cochlear implants. \n\tAdditionally, I represented the community in a government consultation on support services for students with special education needs.",
  },
  {
    img: HKU,
    imgname: "HKU",
    title: "Bachelor & Master of Mechanical Engineering, Student Ambassador",
    period: "Sep 2015 - Aug 2019 & Sep 2020 - Jul 2022",
    bg: HKU_BG,
    jobdescription:
      "\tI pursued my Master's in Engineering (Mechanical Engineering) at the University of Hong Kong from September 2020 to July 2022, focusing on CAD, Renewable Energy, Power Generation, and Electricity quality. My dissertation explored wireless wearable gas sensors for industrial work.\n\tEarlier, I completed my Bachelor's degree in Mechanical Engineering from September 2015 to August 2019, with a focus on Building Services, Electronic Engineering, and Air Conditioning. \n\tAs a Student Ambassador, I led school talks, guided campus tours, and organized events like Open Day and 1-night camp to connect prospective students with faculty and current students.",
  },
  {
    img: TG,
    imgname: "TG",
    title: "Engineer",
    period: "Jul 2019 - Aug 2023",
    bg: TG_BG,
    jobdescription:
      "\tAs a leader in commercial kitchen innovation, I spearheaded the design and fabrication of a cutting-edge smart IoT kitchen, delivering the project on schedule and enhancing operational efficiency. My achievements include engineering a gas flue duct for Japanese gas dryers and implementing a remote carbon monoxide detector system that significantly improved safety standards. I also developed over 10 comprehensive instructional materials to empower sales teams and customers with essential product knowledge. Throughout my career, I have demonstrated strong problem-solving abilities, effective communication, and a collaborative spirit, driving impactful solutions and fostering business growth.",
  },
  {
    img: HKIE,
    imgname: "HKIE",
    title: "President's Protege 2022/2023",
    period: "Oct 2022 -Jun 2023",
    bg: HKIE_BG,
    jobdescription:
      "\tI proudly promoted the profession and its impact on Hong Kong. I directed and produced a music video, 'Proud to be Engineer', which garnered 28,000 views and showcased various engineering projects to the public. Additionally, I organized school events, carnivals, and talks to introduce engineering concepts to a broader audience. As an observer, I attended institutional meetings to stay informed about industry affairs. Through these initiatives, I aimed to raise awareness and appreciation for engineering in Hong Kong, inspiring others to join this vital profession.",
  },
  {
    img: Douglas,
    imgname: "Douglas",
    title: "International Business Management (PDD) & Student Life Volunteer",
    period: "Sep 2023 - Aug 2024",
    bg: Douglas_BG,
    jobdescription:
      " \tAs I begin new journey in International Business Management in 2023-2024, I study on global business and conducted comprehensive analyses on Tesla's long wait time issue and provided actionable insights to improve customer satisfaction. Additionally, I excelled in a market simulation project, ranking second-highest in revenue among five teams. Furthermore, I also worked as an student life volunteer, enhanced logistics and customer service for institutional events, such as Graduation ceremonies, improving efficiency by reducing phototaking times to under a minute and processing over 100 transactions per hour.",
  },
  {
    img: CHHA,
    imgname: "CHHA",
    title: "Volunteer",
    period: "Jan 2024 - Present",
    bg: CHHA_BG,
    jobdescription:
      "\tAs a volunteer at the Canadian Hard of Hearing Association – BC chapter from January 2024 to present, I've delivered technical support with 100% accuracy, providing Communication Access Real-Time Translation (CART) for conferences and enhancing accessibility and communication for participants. Additionally, I've developed a comprehensive database of over 100 contacts to invite schools to public exhibitions, utilizing Microsoft Excel for data organization and management.",
  },
  {
    img: V2RF,
    imgname: "V2RF",
    title: "Project Design Coordinator",
    period: "Feb 2024 - Present",
    bg: V2RF_BG,
    jobdescription:
      "\tWith a passion on entrepreneurship, I've facilitated virtual events to deliver essential resources and foster networking among entrepreneurs and local professionals. I've also developed multicultural promotional content that resulted in a 25% increase in project engagement on global platforms. Additionally, I've contributed to the growth of Canadian start-ups through event facilitation and created over 10 informative videos and Instagram reels to boost workshop participation.",
  },
  {
    img: FRDC,
    imgname: "FRDC",
    title: "Event Assistant",
    period: "May 2024 - Aug 2024",
    bg: FRDC_BG,
    jobdescription:
      "\tAs a marketing intern, I conceptualized and designed visually engaging bus advertisements for Great Gamma's Lake exhibition, using Adobe Illustrator to effectively target family visitors. I also designed and developed the RiverFest 2024 event website using WordPress, implementing advanced SEO strategies to enhance visibility and drive organic traffic. Additionally, I collaborated with a team to sell CAD1,215 worth of 50/50 raffle tickets in just 3 hours by analyzing customer flow patterns and optimizing sales strategies. Throughout my internship, I demonstrated strong creativity, technical skills, and a results-driven approach, contributing to the success of marketing campaigns and events.",
  },
];

export default imgData;
