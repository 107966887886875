import "../index.scss";
import "../scss/journey.scss";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import React, { useRef, useState, useEffect, useCallback } from "react";
import { useInView } from "react-intersection-observer";
import imgData from "./journeydata.js";
function Imgcard(props) {
  let img = props.img;
  let name = props.name;
  let title = props.title;
  let period = props.period;
  let jobdescription = props.jobdescription;
  let altDescription = props.altDescription;
  let bgaltDescription = props.bgaltDescription;
  let bg = props.bg;
  const ref = useRef();
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.65,
  });

  const setRefs = useCallback(
    (node) => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef]
  );
  useEffect(() => {
    props.currentView(name);
  }, [inView]);
  const activeView = "activeView img-container";
  const inactiveView = "img-container";
  return (
    <div className={inView ? activeView : inactiveView} ref={setRefs}>
      <img id="bg" src={bg} alt={bgaltDescription}></img>
      <div id="job">
        <p>{jobdescription}</p>
      </div>
      <img id="logo" src={img} alt={altDescription}></img>
      <p id="title">{title}</p>
      <p id="period">{period}</p>
    </div>
  );
}
function Journeycard(props) {
  const [currentView, setCurrentView] = useState("");
  function handleView(data) {
    props.currentView(data);
  }

  const carDiv = [];
  for (var i = 0; i < imgData.length; i++) {
    let altDescription = "Logo of " + imgData[i].imgname;
    let bgaltDescription = "Background image of " + imgData[i].imgname;
    let img = imgData[i].img;
    let name = imgData[i].imgname;
    let title = imgData[i].title;
    let period = imgData[i].period;
    let jobdescription = imgData[i].jobdescription;
    let bg = imgData[i].bg;
    carDiv.push(
      <Imgcard
        name={name}
        img={img}
        currentView={handleView}
        altDescription={altDescription}
        title={title}
        period={period}
        jobdescription={jobdescription}
        bg={bg}
        bgaltDescription={bgaltDescription}
      ></Imgcard>
    );
  }
  return <div className="JourneycardList">{carDiv}</div>;
}
function Journey() {
  const [currentView, setCurrentView] = useState("HKSD");

  function handleView(data) {
    setCurrentView(data);
  }
  useEffect(() => {}, [currentView]);
  return (
    <>
      <div className=" container-fluid" id="journey">
        <div className="stickyelement">
          <div className="subtitle-container">
            <p className="subtitle">My Journey</p>
          </div>

          <div className="nav-container">
            <nav className="">
              <ul>
                <li className={currentView === "HKSD" ? "active" : "inactive"}>
                  Hong Kong Society of Deaf
                </li>
                <li className={currentView === "HKU" ? "active" : "inactive"}>
                  The University of Hong Kong
                </li>
                <li className={currentView === "TG" ? "active" : "inactive"}>
                  The Hong Kong and China Gas Company Limited
                </li>
                <li className={currentView === "HKIE" ? "active" : "inactive"}>
                  The Hong Kong Institution of Engineers
                </li>
                <li
                  className={currentView === "Douglas" ? "active" : "inactive"}
                >
                  Douglas College
                </li>
                <li className={currentView == "CHHA" ? "active" : "inactive"}>
                  Canadian Hard of Hearing Association
                </li>
                <li className={currentView == "V2RF" ? "active" : "inactive"}>
                  Vision2Reality Foundation
                </li>
                <li className={currentView == "FRDC" ? "active" : "inactive"}>
                  the Fraser River Discovery Centre
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="container-fluid journey-container ">
          <Journeycard currentView={handleView}></Journeycard>
        </div>
      </div>
    </>
  );
}

export default Journey;
