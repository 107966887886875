import React, { useRef, useState, useEffect, useCallback } from "react";
import "../index.scss";
import "../scss/selfie.scss";
import Nav from "./nav.js";

function Selfie() {
  return (
    <>
      <div>
        <title>Hok Lun Holland Lee</title>
      </div>
      <div className="">
        <div className="onePage px-0" id="greeting">
          <div className="selfie ">
            <div className="selfieBackground"></div>
            <img
              className="img-fluid"
              src={require("../img/Selfie BW.png")}
              alt="Selfie of Holland Lee"
            ></img>
          </div>

          <div className="greeting">
            <p className="hi">Hi, I'm</p>
            <p className="name">Holland Lee</p>
            <p className="hi">Web designer + Web developer</p>
            <p className="hi">Engineer + Business student</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Selfie;
